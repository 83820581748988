import React, { useEffect, useRef, useState } from 'react';
import { Dark, DividerColor, Maroon, TextGray, bgFooter, bgColor, btnBgColor, mainColor } from '../../components/Config/Color';
import { FormControl, InputLabel, Select, MenuItem, Grid, useMediaQuery, Typography, Box, Button, Card, TextField, Tooltip, IconButton, ClickAwayListener, InputAdornment, Popper, Paper, Grow } from '@mui/material';
import { Icon } from '@iconify/react';
import SuccessAlert from '../../components/ToastyAlert/SuccessAlert'
import ErrorAlert from '../../components/ToastyAlert/ErrorAlert'
import axios from 'axios';
import { SaveDataRegistrasiTahapI, cekRegistrasiUser, listDataKabupaten, sendEmailVerifikasi } from '../../components/Config/Api';
import CustomTooltip from '../../components/Modal/Tooltip/CustomTooltip';
import LoadingData from '../../components/LoadingPage/LoadingData';
import { DatePicker } from '@mui/x-date-pickers';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Footer from '../../components/Register/Footer/Footer';
import Top from '../../components/Register/Top/Top';
import EmailActivationModal from '../../components/Modal/EmailActivationModal/EmailActivationModal';
import UserRegisterProgressModal from '../../components/Modal/UserRegisterProgressModal.js/UserRegisterProgressModal';

const RegistrasiTahap1 = () => {

    const navigate = useNavigate()

    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(max-width:900px)');

    const [isLoading, setIsLoading] = useState(false)
    const [hideButton, setHideButton] = useState(false)
    const [isPageLoading, setIsPageLoading] = useState(false);



    const handleClickMasukAkun = () => {
        setIsLoading(true)

        const item = localStorage.getItem('item');

        setTimeout(() => {
            setIsLoading(false)
            navigate('/login')
        }, 1500);
    }

    const [isLoadingVerifikasi, setIsLoadingVerifikasi] = useState(false);
    const [openPopperNIK, setOpenPopperNIK] = useState(false);
    const [openPopperKK, setOpenPopperKK] = useState(false);
    const [openPopperDaddy, setOpenPopperDaddy] = useState(false);
    const [openPopperMommy, setOpenPopperMommy] = useState(false);
    const [openPopperEmail, setOpenPopperEmail] = useState(false);
    const [openPopperWhatsapp, setOpenPopperWhatsapp] = useState(false);
    const [openPopperPassword, setOpenPopperPassword] = useState(false);
    const [emailSendNotification, setEmailSendNotification] = useState('N')
    const [loadingSimpanData, setLoadingSimpanData] = useState(false)
    const [loadingBackTahap1, setLoadingBackTahap1] = useState(false)
    const [openEmailActivationModal, setOpenEmailActivationModal] = useState(false)
    const [statusActivationEmail, setStatusActivationEmail] = useState(false)
    const [loadingKonfirmasiEmailActivation, setLoadingKonfirmasiEmailActivation] = useState(false)
    const [listKabupaten, setListKabupaten] = useState([])
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmationPassword, setShowConfirmationPassword] = useState(false);

    const [selectedImageNIK, setSelectedImageNIK] = useState(null);
    const [imageUrlNIK, setImageUrlNIK] = useState(null);

    const [selectedImageKK, setSelectedImageKK] = useState(null);
    const [imageUrlKK, setImageUrlKK] = useState(null);

    const fileInputRefNIK = useRef(null);
    const fileInputRefKK = useRef(null);
    const anchorRefNIK = useRef(null);
    const anchorRefKK = useRef(null);
    const anchorRefDaddy = useRef(null);
    const anchorRefMommy = useRef(null);
    const anchorRefEmail = useRef(null);
    const anchorRefWhatsapp = useRef(null);
    const anchorRefKatasandi = useRef(null);

    const data = localStorage.getItem('data')

    const MAX_IMAGE_SIZE_MB = 1;

    const [newData, setNewData] = useState({
        fullName: '',
        nik: '',
        kk: '',
        nikAyah: '',
        nikIbu: '',
        daddyFullName: '',
        mommyFullName: '',
        tempatLahir: '',
        email: '',
        whatsapp: '',
        whatsappOrangtua: '',
        password: '',
        konfirmasiPassword: '',
    })

    const [emailError, setEmailError] = useState('');
    const [KTPError, setKTPError] = useState('');
    const [KTPAyahError, setKTPAyahError] = useState('');
    const [KTPIbuError, setKTPIbuError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [konfirmasiPassword, setKonfirmasiPassword] = useState('');
    const [autoFocus, setAutoFocus] = useState({
        ktp: false,
        email: false,
        fotoKtp: false,
        fotokk: false,
        email: false,
        password: false,
        konfirmasiPassword: false
    });

    const [countdownTimer, setCountdownTimer] = useState(0)
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [error, setError] = useState(null);
    const [statusEmail, setStatusEmail] = useState('');
    const timerId = useRef()

    const [errorLocation, setErrorLocation] = useState(null);
    const [dataWilayahKabupaten91, setDataWilayahKabupaten91] = useState([]);
    const [dataWilayah91, setDataWilayah91] = useState([]);
    const [dataWilayahKabupaten94, setDataWilayahKabupaten94] = useState([]);
    const [dataWilayah94, setDataWilayah94] = useState([]);
    const [asalKabupaten, setAsalKabupaten] = useState('Kab. Nabire')

    const [cekUserProgress, setCekUserProgress] = useState('');
    const [handleNavigasi, setHandleNavigasi] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [userProgress, setUserProgress] = useState('');
    const [openUserRegisterProgressModal, setOpenUserRegisterProgressModal] = useState(false);
    const [OpenHandleRegistrationUserModal, setOpenHandleRegistrationUserModal] = useState(false);
    const [monthAndYear, setMonthAndYear] = useState(null);


    const [isDragging, setIsDragging] = useState({
        isDraggingNIK: false,
        isDraggingKK: false,
    });

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleClickShowConfirmationPassword = () => {
        setShowConfirmationPassword(!showConfirmationPassword);
    };

    useEffect(() => {
        timerId.current = setInterval(() => {
            setCountdownTimer(prev => prev - 1)
        }, 1000)
        return () => clearInterval(timerId.current)
    }, [countdownTimer])

    useEffect(() => {
        if (countdownTimer <= 0) {
            clearInterval(timerId.current)
        }
    }, [countdownTimer])

    useEffect(() => {
        // console.log('asdsad', countdownTimer);
        if (countdownTimer <= 0) {
            setOpenEmailActivationModal(false)
        }
    }, [countdownTimer])


    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleImageUploadNIK = () => {
        fileInputRefNIK.current.click();
    };

    const handleImageUploadKK = () => {
        fileInputRefKK.current.click();
    };

    const handleFileInputChangeNIK = (file) => { // Menyesuaikan nama fungsi
        // console.log(file);
        if (file) {
            const fileType = file.type;
            const allowedImageTypes = ['image/jpeg', 'image/png'];
            const allowedPdfTypes = ['application/pdf'];

            const fileSizeInMB = file.size / (1024 * 1024);
            if (fileSizeInMB > MAX_IMAGE_SIZE_MB) {
                ErrorAlert({
                    message: 'Ukuran Maksimal Foto/Pdf 1 MB',
                    position: 'top-center'
                });
                return;
            } else {
                if (allowedImageTypes.includes(fileType)) {
                    setSelectedImageNIK(file);
                    const imageUrl = URL.createObjectURL(file);
                    setImageUrlNIK(imageUrl);

                } else if (allowedPdfTypes.includes(fileType)) {
                    setSelectedImageNIK(file)
                    const imageUrl = URL.createObjectURL(file);
                    setImageUrlNIK(imageUrl);
                } else {
                    console.error('Unsupported file type');
                    ErrorAlert({
                        message: 'Hanya menerima file jpeg, png dan pdf.',
                        position: 'top-center'
                    })
                    return;
                }
            }
        }
    };

    const handleFileInputChangeKK = (file) => { // Menyesuaikan nama fungsi
        // console.log(file);
        if (file) {
            const fileType = file.type;
            const allowedImageTypes = ['image/jpeg', 'image/png'];
            const allowedPdfTypes = ['application/pdf'];

            const fileSizeInMB = file.size / (1024 * 1024);
            if (fileSizeInMB > MAX_IMAGE_SIZE_MB) {
                ErrorAlert({
                    message: 'Ukuran Maksimal Foto 1 MB',
                    position: 'top-center'
                });
                return;
            } else {
                if (allowedImageTypes.includes(fileType)) {
                    setSelectedImageKK(file);
                    const imageUrl = URL.createObjectURL(file);
                    setImageUrlKK(imageUrl);
                } else if (allowedPdfTypes.includes(fileType)) {
                    setSelectedImageKK(file);
                    const imageUrl = URL.createObjectURL(file);
                    setImageUrlKK(imageUrl);
                } else {
                    console.error('Unsupported file type');
                    ErrorAlert({
                        message: 'Hanya menerima file jpeg, png dan pdf.',
                        position: 'top-center'
                    })
                    return;
                }
            }
        }
    };

    const handleRemoveImageNIK = () => {
        setSelectedImageNIK(null);
        setImageUrlNIK(null);
    };

    const handleRemoveImageKK = () => {
        setSelectedImageKK(null);
        setImageUrlKK(null);
    };

    const getDataWilayahKab = async () => {
        let uniqueKabName = new Set()
        await axios.get(`${listDataKabupaten}/${94}`)
            .then((response) => {
                // console.log('list', response.data);
                const res = response?.data?.data
                res.forEach((data) => {
                    uniqueKabName.add(data.name);
                });

                // console.log('uniqueKabName', uniqueKabName);

                // Konversi Set kembali ke array
                const uniqueKabNameArray = Array.from(uniqueKabName);
                setListKabupaten(uniqueKabNameArray)
            }).catch((error) => {
                console.log(error.response);
            })
    }

    useEffect(() => {
        const dataLogin = localStorage.getItem('LogInfo')
        if (dataLogin) {
            navigate('/dashboard-applicant')
        } else {
            setIsPageLoading(true)
            setTimeout(() => {
                getDataWilayahKab();
                window.scrollTo(0, 0);
                setIsPageLoading(false)
            }, 2500);
        }
    }, []);


    const handleChange = (e) => {
        const { name, value } = e.target;

        setNewData(prev => {
            return { ...prev, [name]: value };
        });

        if (name === 'nikAyah') {
            if (value !== '' && value.length < 16) {
                setKTPAyahError('Nomor KTP minimal 16 digit');
            } else {
                setKTPAyahError('');
            }
        }

        if (name === 'nikIbu') {
            if (value !== '' && value.length < 16) {
                setKTPIbuError('Nomor KTP minimal 16 digit');
            } else {
                setKTPIbuError('');
            }
        }

        // Validate NIK format
        if (name === 'nik') {

            if (value !== '' && value.length < 16) {
                setKTPError('Nomor KTP minimal 16 digit');
            } else {
                setKTPError('');
            }
        }
        if (name === 'email') {
            // Validate email format
            if (value !== '' && !validateEmail(value)) {
                setEmailError('Format Email Salah');
            } else {
                setEmailError('');
            }


        } else if (name === 'password') {
            // Validasi password
            if (value !== '') {
                // Cek apakah password memiliki huruf besar, huruf kecil, dan simbol
                const hasUpperCase = /[A-Z]/.test(value);
                const hasLowerCase = /[a-z]/.test(value);
                const hasSymbol = /\W/.test(value);
                const hasNumber = /\d/.test(value);

                // Jika password tidak memenuhi semua syarat, set error
                if (!hasUpperCase) {
                    setPasswordError('Kata sandi harus mengandung setidaknya satu huruf besar');
                } else if (!hasLowerCase) {
                    setPasswordError('Kata sandi harus mengandung setidaknya satu huruf kecil');
                } else if (!hasNumber) {
                    setPasswordError('Kata sandi harus mengandung setidaknya satu angka');
                } else if (!hasSymbol) {
                    setPasswordError('Kata sandi harus mengandung setidaknya satu simbol');
                } else {
                    // Jika password memenuhi semua syarat, hapus error
                    setPasswordError('');
                }
            } else {
                // Jika password kosong, hapus error
                setPasswordError('');
            }
        } else if (name === 'konfirmasiPassword') {
            if (value !== '' && newData.password !== value) {
                setKonfirmasiPassword('Konfirmasi password salah.');
            } else {
                setKonfirmasiPassword('');
            }
        }
    };

    const handleSendEmail = async () => {

        const formData = new FormData();
        formData.append('personal_email', newData.email);
        setIsPageLoading(true)
        setIsLoadingVerifikasi(true)

        try {

            if (newData.email !== '' && newData.nik !== '' && newData.nik.length >= 16 && newData.fullName !== '') {
                const response = await axios.post(`${cekRegistrasiUser}`, formData);
                console.log('handlesendemail', response.data);

                const { tahap, verificationEmail, verificationUser, scholarship, personalInformation, academic } = response.data
                // console.log('verificationEmail', verificationEmail);
                // console.log('verificationUser', verificationUser);
                // console.log('scholarship', scholarship);
                // console.log('personalInformation', personalInformation);
                // console.log('academic', academic);

                setCekUserProgress(response.data)

                if (
                    !tahap.tahap_2 &&
                    !tahap.tahap_3 &&
                    verificationEmail.keterangan === 'tidak_masuk_tabel_email' &&
                    verificationUser.keterangan === 'tidak_masuk_tabel_user' &&
                    scholarship.keterangan === 'tidak_masuk_tabel_scholarship' &&
                    personalInformation.keterangan === 'tidak_masuk_tabel_personal' &&
                    academic.keterangan === 'tidak_masuk_tabel_academic'
                ) {
                    await axios.post(sendEmailVerifikasi, {
                        name: newData.fullName,
                        email: newData.email
                    }).then((response) => {
                        console.log('sendEmailVerifikasi', response);

                        // SuccessAlert({
                        //     message: 'Email verifikasi dikirim ke ' + newData.email,
                        //     position: 'top-center'
                        // })
                        // const { verification_email_id } = response.data.data
                        // console.log('verification_email_id', verification_email_id);

                        setIsLoadingVerifikasi(false);
                        setOpenEmailActivationModal(true)
                        setIsPageLoading(false)
                    }).catch((error) => {
                        console.log(error.response);
                        if (error.code === 'ERR_NETWORK' || error.message.includes('ERR_CONNECTION_REFUSED')) {
                            ErrorAlert({
                                message: 'Tidak dapat terhubung ke server. Pastikan Anda terkoneksi ke internet.',
                                position: 'top-center'
                            })
                        }
                        if (error.response?.data?.messages?.email) {
                            // ErrorAlert({
                            //     message: error.response.data.messages.email === "The email has already been taken." ? 'Email sudah terdaftar.' : error.response.data.messages.email,
                            //     position: 'top-center'
                            // })
                            if (error.response.data.messages.email === "The email has already been taken.") {
                                setOpenEmailActivationModal(true)
                                setErrorMessage('The email has already been taken.')
                            }
                        }
                        setIsLoadingVerifikasi(false);
                        setIsPageLoading(false)
                    })
                } else if (
                    !tahap.tahap_2 &&
                    !tahap.tahap_3 &&
                    verificationEmail.keterangan === 'masuk_tabel_email' &&
                    verificationUser.keterangan === 'tidak_masuk_tabel_user' &&
                    scholarship.keterangan === 'tidak_masuk_tabel_scholarship' &&
                    personalInformation.keterangan === 'tidak_masuk_tabel_personal' &&
                    academic.keterangan === 'tidak_masuk_tabel_academic'
                ) {
                    setOpenEmailActivationModal(true)
                    setErrorMessage('The email has already been taken.')
                    setIsPageLoading(false)
                } else if (
                    !tahap.tahap_2 &&
                    !tahap.tahap_3 &&
                    verificationEmail.keterangan === 'masuk_tabel_email' &&
                    verificationUser.keterangan === 'masuk_tabel_user' &&
                    scholarship.keterangan === 'tidak_masuk_tabel_scholarship' &&
                    personalInformation.keterangan === 'masuk_tabel_personal' &&
                    academic.keterangan === 'tidak_masuk_tabel_academic'
                ) {
                    setUserProgress('tahap_2')
                    setOpenUserRegisterProgressModal(true)
                    setIsLoadingVerifikasi(false)
                    setIsPageLoading(false)
                } else if (
                    tahap.tahap_2 &&
                    !tahap.tahap_3 &&
                    verificationEmail.keterangan === 'masuk_tabel_email' &&
                    verificationUser.keterangan === 'masuk_tabel_user' &&
                    scholarship.keterangan === 'masuk_tabel_scholarship' &&
                    personalInformation.keterangan === 'masuk_tabel_personal' &&
                    academic.keterangan === 'masuk_tabel_academic'
                ) {
                    setUserProgress('tahap_3')
                    setOpenUserRegisterProgressModal(true)
                    setIsLoadingVerifikasi(false)
                    setIsPageLoading(false)
                } else if (
                    tahap.tahap_2 &&
                    tahap.tahap_3 &&
                    verificationEmail.keterangan === 'masuk_tabel_email' &&
                    verificationUser.keterangan === 'masuk_tabel_user' &&
                    scholarship.keterangan === 'masuk_tabel_scholarship' &&
                    personalInformation.keterangan === 'masuk_tabel_personal' &&
                    academic.keterangan === 'masuk_tabel_academic'
                ) {
                    setUserProgress('done')
                    setOpenUserRegisterProgressModal(true)
                    setIsLoadingVerifikasi(false)
                    setIsPageLoading(false)
                } else {
                    setUserProgress('done')
                    setOpenUserRegisterProgressModal(true)
                    setIsLoadingVerifikasi(false)
                    setIsPageLoading(false)
                }

            } else if (newData.email === '') {
                ErrorAlert({
                    message: 'Email harus diisi.',
                    position: 'top-center',
                });
                setIsLoadingVerifikasi(false)
                setIsPageLoading(false)
            } else if (newData.fullName === '') {
                ErrorAlert({
                    message: 'Nama harus diisi.',
                    position: 'top-center',
                })
                setIsLoadingVerifikasi(false)
                setIsPageLoading(false)
            } else if (newData.nik === '') {
                ErrorAlert({
                    message: 'Nomor KTP harus diisi.',
                    position: 'top-center',
                })
                setIsLoadingVerifikasi(false)
                setIsPageLoading(false)
            } else if (!validateEmail(newData.email)) {
                ErrorAlert({
                    message: 'Email tidak valid.',
                    position: 'top-center',
                })
                setIsLoadingVerifikasi(false)
                setIsPageLoading(false)
            } else if (newData.nik.length < 16) {
                ErrorAlert({
                    message: 'Nomor KTP minimal 16 digit.',
                    position: 'top-center',
                })
                setIsLoadingVerifikasi(false)
                setIsPageLoading(false)
            }

        } catch (error) {
            console.log(error.response);
            if (error.code === 'ERR_NETWORK' || error.message.includes('ERR_CONNECTION_REFUSED')) {
                ErrorAlert({
                    message: 'Tidak dapat terhubung ke server. Pastikan Anda terkoneksi ke internet.',
                    position: 'top-center'
                })
            }
            setIsLoadingVerifikasi(false)
            setIsPageLoading(false)
        }

    }

    const activasionEmail = localStorage.getItem('ActivasiEmail')

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoadingSimpanData(true)
        setIsPageLoading(true)
        if (activasionEmail) {
            const { email, verification_email_id } = JSON.parse(activasionEmail)

            const formData = new FormData();

            formData.append('user_id', verification_email_id)
            formData.append('full_name', newData.fullName)
            formData.append('origin_district', asalKabupaten)
            formData.append('whatsapp_number', newData.whatsapp)
            formData.append('relatives_whatsapp', newData.whatsappOrangtua)
            formData.append('personal_email', newData.email)
            formData.append('nik_ktp', newData.nik)
            formData.append('ktp_scan', selectedImageNIK)
            formData.append('place_of_birth', newData.tempatLahir)
            formData.append('family_card_number', newData.kk)
            formData.append('family_card_scan', selectedImageKK)
            formData.append('mother_name', newData.mommyFullName)
            formData.append('father_name', newData.daddyFullName)
            formData.append('mother_nik', newData.nikIbu)
            formData.append('father_nik', newData.nikAyah)
            formData.append('date_of_birth', moment(monthAndYear).format('YYYY-MM-DD'))
            formData.append('password', newData.password)
            formData.append('password2', newData.konfirmasiPassword)


            // for (let pair of formData.entries()) {
            //     console.log(pair[0] + ', ' + pair[1]);
            // }


            await axios.post(SaveDataRegistrasiTahapI, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }).then((response) => {
                console.log('personalinformation', response.data);

                SuccessAlert({
                    message: 'Berhasil simpan data.',
                    position: 'top-center'
                })

                // localStorage.setItem('ActivasiEmail', JSON.stringify({
                //     email: newData.email,
                //     verification_email_id: response.data.user_id,
                //     nik_ktp: newData.nik,
                // }))

                setTimeout(() => {
                    navigate('/registrasi-tahap-2')
                    setLoadingSimpanData(false)
                    setIsPageLoading(false)
                }, 3000);
            }).catch((error) => {
                console.error(error?.response);

                if (error?.response?.data?.messages?.nik_ktp) {
                    ErrorAlert({
                        message: error?.response.data?.messages?.nik_ktp,
                        position: 'top-center'
                    });
                    setLoadingSimpanData(false)
                } else if (error?.response?.data?.messages?.personal_email) {
                    ErrorAlert({
                        message: error?.response.data?.messages?.personal_email,
                        position: 'top-center'
                    });
                    setLoadingSimpanData(false)
                } else if (error?.response?.data?.messages?.father_nik) {
                    ErrorAlert({
                        message: error?.response.data?.messages?.father_nik,
                        position: 'top-center'
                    });
                    setLoadingSimpanData(false)
                } else if (error?.response?.data?.messages?.mother_nik) {
                    ErrorAlert({
                        message: error?.response.data?.messages?.mother_nik,
                        position: 'top-center'
                    });
                    setLoadingSimpanData(false)
                }
                if (error.code === 'ERR_NETWORK' || error.message.includes('ERR_CONNECTION_REFUSED')) {
                    ErrorAlert({
                        message: 'Tidak dapat terhubung ke server. Pastikan Anda terkoneksi ke internet.',
                        position: 'top-center'
                    })
                    setLoadingSimpanData(false)

                }
                setIsPageLoading(false)
            });
        }

    };



    const handleShowPDF = (fileName) => {

        return (
            <>
                <Icon icon='bxs:file-pdf' style={{ fontSize: '100px', color: mainColor }} />
                <Typography
                    sx={{
                        fontFamily: 'Plus Jakarta Sans',
                        color: mainColor,
                        fontSize: '11px',
                        fonStyle: 'normal',
                        fontWeight: 'bold',
                        lineHeight: 'normal',
                        cursor: 'pointer',
                        textAlign: 'center',
                        marginTop: '-10px'
                    }}
                >
                    {fileName}
                </Typography>
            </>
        )
    }

    const handleSelectKabupaten = (e) => {
        setAsalKabupaten(e.target.value)
    }

    const handleDragOverKK = (event) => {
        event.preventDefault();
        // console.log('handleDragOver', event);
        setIsDragging({
            isDraggingKK: true,
        })
    };

    const handleDropKK = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        // console.log('handleDrop', file);
        handleFileInputChangeKK(file);
        setIsDragging({
            isDraggingKK: false
        })
    };

    const handleDragOverNIK = (event) => {
        event.preventDefault();
        // console.log('handleDragOver', event);
        setIsDragging({
            isDraggingNIK: true
        })
    };

    const handleDropNIK = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        // console.log('handleDrop', file);
        handleFileInputChangeNIK(file);
        setIsDragging({
            isDraggingNIK: false
        })
    };

    const handleDragLeave = () => {
        setIsDragging({
            isDraggingNIK: false,
            isDraggingKK: false
        }) // Set state menjadi false saat dragleave
    };

    return (
        <>
            <Top />
            <Grid
                container
                sx={{
                    backgroundColor: bgColor,
                    padding: isMobile ? '7px 15px' : isTablet ? '7px 20px' : '7px 83px',
                    height: 'auto', // Change height to auto
                    overflow: 'hidden',
                    minHeight: 'calc(100vh - 300px)', // Ensure content takes at least the full viewport height minus header/footer
                }}
            >
                <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                        height: isMobile ? '8%' : '10%', // Adjust height based on screen size
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: isMobile ? '13px' : isTablet ? '13px' : '23px',
                        // backgroundColor:' orange'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            // gap: '10px'
                        }}
                    >
                        <Button
                            // variant='outlined'
                            onClick={() => {
                                window.location.href = 'https://beasiswapapuatengah.id'
                            }}
                            sx={{
                                textTransform: 'none',
                                color: 'black',
                                '&:hover': {
                                    color: mainColor, // Change text color to white on hover
                                    backgroundColor: bgColor
                                },
                            }}
                        >
                            <Icon icon='material-symbols:arrow-back' />
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: isMobile ? '12px' : '13px',
                                    fontWeight: '400',
                                    lineHeight: '20.16px',
                                    cursor: 'pointer',
                                    marginLeft: '10px'
                                }}
                            >
                                Kembali ke Beranda
                            </Typography>
                        </Button>
                    </Box>
                    <Button
                        onClick={handleClickMasukAkun}
                        variant='contained'
                        size='small'
                        sx={{
                            minWidth: isMobile ? '120px' : '150px',
                            // maxWidth:'100px',
                            backgroundColor: btnBgColor,
                            color: mainColor,
                            fontSize: '13px',
                            fontWeight: 'bold',
                            textTransform: 'none',
                            padding: isMobile ? '0x 10px' : undefined,
                            '&:hover': {
                                color: 'white', // Change text color to white on hover
                                backgroundColor: mainColor
                            },
                        }}
                        disabled={isLoading ? true : false}
                    >
                        {isLoading ?
                            <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px' }} />
                            :
                            'Masuk Akun'
                        }
                    </Button>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                        margin: isMobile ? '40px 0px 0px 0px' : '30px 0px 34px 0px',
                        height: '5%', // You can adjust the height of other content items if needed
                        // backgroundColor: 'orange',
                    }}
                >
                    <Box
                        sx={{
                            marginBottom: isMobile ? '20px' : '10px'
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Jakarta Sans, sans-serif',
                                fontSize: isMobile ? '18px' : '22px',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                lineHeight: 'normal',
                                color: '#1E222F'
                            }}
                        >
                            Pendaftaran Beasiswa Papua Tengah
                        </Typography>
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                        height: '70%', // You can adjust the height of other content items if needed
                        overflowY: 'auto'
                        // backgroundColor: 'orange',
                    }}
                >
                    <Card
                        sx={{
                            display: 'flex',
                            // justifyContent:'center',
                            // alignItems:'center'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                gap: '10px',
                                padding: isMobile ? '10px' : '15px',
                                backgroundColor: mainColor,
                                width: '33.3%',
                                // cursor: 'pointer',
                                borderRadius: '5px'
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    color: 'white',
                                    fontSize: '12px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: 'normal',
                                    textAlign: 'left', // Set text alignment to left
                                }}
                            >
                                Tahap I
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    color: 'white',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    fontSize: isMobile ? '13px' : '14px',
                                    lineHeight: isMobile ? '21px' : 'normal',
                                    textAlign: 'left', // Set text alignment to left
                                }}
                            >
                                Registrasi Akun
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                gap: '10px',
                                padding: '15px',
                                backgroundColor: 'white',
                                width: '33.3%',
                                // cursor: 'pointer',
                                borderRadius: '5px'
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    color: 'black',
                                    fontSize: '12px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: 'normal',
                                    textAlign: 'left', // Set text alignment to left
                                }}
                            >
                                Tahap II
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    color: 'black',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    fontSize: isMobile ? '13px' : '14px',
                                    lineHeight: isMobile ? '21px' : 'normal',
                                    textAlign: 'left', // Set text alignment to left
                                }}
                            >
                                Lengkapi Persyaratan
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                gap: '10px',
                                padding: '15px',
                                backgroundColor: 'white',
                                width: '33.3%',
                                // cursor: 'pointer',
                                borderRadius: '5px'
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    color: 'black',
                                    fontSize: '12px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: 'normal',
                                    textAlign: 'left', // Set text alignment to left
                                }}
                            >
                                Tahap III
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    color: 'black',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    fontSize: isMobile ? '13px' : '14px',
                                    lineHeight: isMobile ? '21px' : 'normal',
                                    textAlign: 'left', // Set text alignment to left
                                }}
                            >
                                Menunggu Verifikasi Data
                            </Typography>
                        </Box>
                    </Card>
                    <Box
                        sx={{
                            margin: isMobile ? '46px 0px 12px 0px' : '56px 0px 24px 0px',
                            padding: isMobile ? '0px 0px' : isTablet ? '0px 50px' : '0px 160px',
                        }}
                    >
                        <form onSubmit={handleSubmit}>
                            <Grid container >
                                <Grid item xs={12} md={12} mb="30px">
                                    <Box
                                        sx={{

                                            backgroundColor: '#F8D70033',
                                            borderRadius: '10px',
                                            width: { xs: '100%', md: '78%' }, // Atur responsif
                                            maxWidth: '78%',
                                            minWidth: '100%',
                                            margin: '0 auto', // Margin untuk menengahkan box
                                        }}
                                    >
                                        <Box

                                            sx={{
                                                padding: '10px',
                                            }}>
                                            <Typography
                                                sx={{
                                                    color: '#1E222F',
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: { xs: '15px', md: '17px' }, // Font size responsif
                                                    fontWeight: 'bold',
                                                    lineHeight: '24px',
                                                    textAlign: 'justify',
                                                    marginBottom: '15px',
                                                    wordWrap: 'break-word', // Supaya teks tidak melebihi lebar layar di mobile
                                                }}
                                            >
                                                Pengumuman Penting!
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    color: '#1E222F',
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: { xs: '15px', md: '16px' }, // Font size responsif
                                                    fontWeight: '500',
                                                    lineHeight: '24px',
                                                    textAlign: 'justify',
                                                    wordWrap: 'break-word', // Supaya teks tidak melebihi lebar layar di mobile
                                                }}
                                            >
                                                Bagi mahasiswa yang telah mendaftar periode{' '}
                                                <span style={{ fontWeight: 'bold' }}>Juli - 7 Oktober 2024</span>. Diharapkan untuk melengkapi data dengan cara masuk akun di{' '}
                                                <a
                                                    href="https://applicant.beasiswapapuatengah.id/login"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    style={{
                                                        textDecoration: 'none',
                                                        color: 'blue',
                                                    }}
                                                >
                                                    https://applicant.beasiswapapuatengah.id/login
                                                </a>{' '}
                                                kemudian melengkapi data pendaftaran.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Jakarta Sans, sans-serif',
                                            fontSize: isMobile ? '15px' : '16px',
                                            fontStyle: 'normal',
                                            fontWeight: '500',
                                            lineHeight: 'normal',
                                            marginBottom: isMobile ? '12px' : '24px',
                                            textAlign: 'justify',
                                            letterSpacingL: '1.5px',
                                            lineHeight: '20px'
                                        }}
                                    >
                                        Lengkapi form berikut untuk melakukan pendaftaran akun.
                                    </Typography>
                                </Grid>

                                {/* Nama Lengkap */}
                                <Grid item xs={12} md={12} >
                                    <Box
                                        sx={{
                                            width: isMobile ? '100%' : '40%',
                                            maxWidth: '40%',
                                            minWidth: isMobile ? '100%' : '40px',
                                            // marginTop: '24px'
                                        }}
                                    >
                                        <TextField
                                            fullWidth
                                            variant="standard"
                                            type="text"
                                            label="Masukkan Nama Lengkap(Sesuai KTP)"
                                            placeholder='Masukkan nama lengkap'
                                            value={newData.fullName}
                                            name="fullName"
                                            onChange={(e) => handleChange(e)}
                                            autoComplete="off"
                                            minLength={16}
                                            required
                                            sx={{
                                                marginBottom: isMobile ? '24px' : '34px',
                                                fontSize: isMobile ? '14px' : '13px',
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "white",
                                                },
                                                "& .MuiFilledInput-root.Mui-disabled:hover": {
                                                    "& fieldset": {
                                                        borderBottomColor: mainColor,
                                                    },
                                                },
                                                "& .MuiInputLabel-root.Mui-disabled": {
                                                    color: mainColor,
                                                },
                                                "& .MuiFilledInput-underline:before": {
                                                    borderBottomColor: mainColor,
                                                },
                                                "& .MuiInputLabel-root": {
                                                    color: '#B3B3D4',
                                                    fontWeight: 'bold',
                                                    fontSize: '13px',
                                                },
                                                "& .MuiInputLabel-root.Mui-focused": {
                                                    color: mainColor,
                                                },
                                                "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                                    color: mainColor,
                                                },
                                                "& .MuiFilledInput-underline:hover:before": {
                                                    borderBottomColor: mainColor,
                                                },
                                                "& .MuiFilledInput-underline:hover:after": {
                                                    borderBottomColor: mainColor,
                                                },
                                                "& .MuiFilledInput-underline:focus": {
                                                    borderBottomColor: mainColor,
                                                },
                                                "& .MuiFilledInput-underline:focus:before": {
                                                    borderBottomColor: mainColor,
                                                },
                                                "& .MuiFilledInput-underline:focus:after": {
                                                    borderBottomColor: mainColor,
                                                },
                                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                                {
                                                    "-webkit-appearance": "none",
                                                    margin: 0,
                                                },
                                                "& input[type=number]": {
                                                    "-moz-appearance": "textfield",
                                                },
                                            }}
                                        />
                                    </Box>
                                </Grid>

                                {/* Nomor KTP */}
                                <Grid item xs={12} md={12}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        textAlign: 'center',
                                        gap: '24px'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: isMobile ? '100%' : '40%',
                                            maxWidth: '40%',
                                            minWidth: isMobile ? '100%' : '40%'
                                        }}
                                    >
                                        <TextField
                                            fullWidth
                                            variant="standard"
                                            type="text"
                                            label="Nomor KTP"
                                            placeholder='Masukkan Nomor KTP'
                                            value={newData.nik}
                                            name="nik"
                                            onChange={(e) => handleChange(e)}
                                            onInput={(e) => {
                                                // Filter out non-numeric characters
                                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                            }}
                                            autoComplete="off"
                                            error={newData.nik === '' ? undefined : !!KTPError}
                                            helperText={KTPError}
                                            required
                                            autoFocus={autoFocus.ktp}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        {isMobile && (
                                                            <Icon
                                                                icon='ep:info-filled'
                                                                onClick={() => setOpenPopperNIK(true)}
                                                                style={{ fontSize: '22px', cursor: 'pointer', color: mainColor }}
                                                                ref={anchorRefNIK}
                                                            />
                                                        )}
                                                        <Popper
                                                            open={openPopperNIK}
                                                            anchorEl={anchorRefNIK.current}
                                                            placement="top"
                                                            transition
                                                            // disablePortal
                                                            modifiers={[{ name: 'flip', enabled: false }]}
                                                            style={{ zIndex: 9999, }}
                                                        >
                                                            {({ TransitionProps, placement }) => (
                                                                <Grow
                                                                    {...TransitionProps}
                                                                    style={{
                                                                        transformOrigin:
                                                                            placement === 'top-end' ? 'right bottom' : 'right top',
                                                                    }}
                                                                >
                                                                    <Paper
                                                                        elevation={3}
                                                                        sx={{
                                                                            padding: '5px',
                                                                            margin: '0px 10px'
                                                                        }}
                                                                    >
                                                                        {/* Tooltip diletakkan di dalam ClickAwayListener */}
                                                                        <ClickAwayListener onClickAway={() => setOpenPopperNIK(false)}>
                                                                            <Tooltip title="Hanya menerima KTP dengan NIK yang terdaftar sebagai penduduk Provinsi Papua Tengah." arrow>
                                                                                {/* Children yang dibutuhkan oleh Tooltip (misalnya, title) */}
                                                                                <Box>
                                                                                    <Typography
                                                                                        sx={{
                                                                                            textAlign: 'center'
                                                                                        }}
                                                                                    >
                                                                                        Hanya menerima KTP dengan NIK yang terdaftar sebagai penduduk Provinsi Papua Tengah.
                                                                                    </Typography>
                                                                                </Box>
                                                                            </Tooltip>
                                                                        </ClickAwayListener>
                                                                    </Paper>
                                                                </Grow>
                                                            )}
                                                        </Popper>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            sx={{
                                                // marginBottom: isMobile ? '24px' : '34px',
                                                fontSize: isMobile ? '14px' : '13px',
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "white",
                                                },
                                                "& .MuiFilledInput-root.Mui-disabled:hover": {
                                                    "& fieldset": {
                                                        borderBottomColor: mainColor,
                                                    },
                                                },
                                                "& .MuiInputLabel-root.Mui-disabled": {
                                                    color: mainColor,
                                                },
                                                "& .MuiFilledInput-underline:before": {
                                                    borderBottomColor: mainColor,
                                                },
                                                "& .MuiInputLabel-root": {
                                                    color: '#B3B3D4',
                                                    fontWeight: 'bold',
                                                    fontSize: '13px'
                                                },
                                                "& .MuiInputLabel-root.Mui-focused": {
                                                    color: mainColor,
                                                },
                                                "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                                    color: mainColor,
                                                },
                                                "& .MuiFilledInput-underline:hover:before": {
                                                    borderBottomColor: mainColor,
                                                },
                                                "& .MuiFilledInput-underline:hover:after": {
                                                    borderBottomColor: mainColor,
                                                },
                                                "& .MuiFilledInput-underline:focus": {
                                                    borderBottomColor: mainColor,
                                                },
                                                "& .MuiFilledInput-underline:focus:before": {
                                                    borderBottomColor: mainColor,
                                                },
                                                "& .MuiFilledInput-underline:focus:after": {
                                                    borderBottomColor: mainColor,
                                                },
                                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                                {
                                                    "-webkit-appearance": "none",
                                                    margin: 0,
                                                },
                                                "& input[type=number]": {
                                                    "-moz-appearance": "textfield",
                                                },
                                            }}
                                        />
                                    </Box>
                                    {!isMobile && (
                                        <CustomTooltip
                                            message='Hanya menerima KTP dengan NIK terdaftar sebagai penduduk Provinsi Papua Tengah.'
                                            padding='0px 8px'
                                        />
                                    )}
                                </Grid>

                                {/* Textfield Email */}
                                <Grid item xs={12} md={12}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        textAlign: 'center',
                                        gap: '24px',
                                        marginTop: '24px'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: isMobile ? '100%' : '40%',
                                            maxWidth: '40%',
                                            minWidth: isMobile ? '100%' : '40%'
                                        }}
                                    >
                                        <TextField
                                            fullWidth
                                            variant="standard"
                                            type="text"
                                            label="Email"
                                            placeholder='Masukkan email aktif'
                                            value={newData.email}
                                            name="email"
                                            onChange={(e) => handleChange(e)}
                                            error={newData.email === '' ? undefined : !!emailError}
                                            helperText={emailError}
                                            autoComplete="off"
                                            required
                                            autoFocus={autoFocus.email}
                                            disabled={statusActivationEmail ? true : false}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        {isMobile && (
                                                            <Icon
                                                                icon='ep:info-filled'
                                                                onClick={() => setOpenPopperEmail(true)}
                                                                style={{ fontSize: '22px', cursor: 'pointer', color: mainColor }}
                                                                ref={anchorRefEmail}
                                                            />
                                                        )}
                                                        <Popper
                                                            open={openPopperEmail}
                                                            anchorEl={anchorRefEmail.current}
                                                            placement="top"
                                                            transition
                                                            // disablePortal
                                                            modifiers={[{ name: 'flip', enabled: false }]}
                                                            style={{ zIndex: 9999 }}
                                                        >
                                                            {({ TransitionProps, placement }) => (
                                                                <Grow
                                                                    {...TransitionProps}
                                                                    style={{
                                                                        transformOrigin:
                                                                            placement === 'top-end' ? 'right bottom' : 'right top',
                                                                    }}
                                                                >
                                                                    <Paper
                                                                        elevation={3}
                                                                        sx={{
                                                                            padding: '5px',
                                                                            margin: '0px 10px',
                                                                        }}
                                                                    >
                                                                        {/* Tooltip diletakkan di dalam ClickAwayListener */}
                                                                        <ClickAwayListener onClickAway={() => setOpenPopperEmail(false)}>
                                                                            <Tooltip title="Pastikan email yang dimasukkan masih dalam keadaan aktif dan dapat menerima atau mengirim email." arrow>
                                                                                {/* Children yang dibutuhkan oleh Tooltip (misalnya, title) */}
                                                                                <Box>
                                                                                    <Typography
                                                                                        sx={{
                                                                                            textAlign: 'center'
                                                                                        }}
                                                                                    >
                                                                                        Pastikan email yang dimasukkan masih dalam keadaan aktif dan dapat menerima atau mengirim email.
                                                                                    </Typography>

                                                                                </Box>
                                                                            </Tooltip>
                                                                        </ClickAwayListener>
                                                                    </Paper>
                                                                </Grow>
                                                            )}
                                                        </Popper>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            sx={{
                                                // marginBottom: isMobile ? '24px' : '34px',
                                                fontSize: isMobile ? '14px' : '13px',
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                    // WebkitTextFillColor: "white",
                                                },
                                                "& .MuiFilledInput-root.Mui-disabled:hover": {
                                                    "& fieldset": {
                                                        borderBottomColor: mainColor,
                                                    },
                                                },
                                                "& .MuiInputLabel-root.Mui-disabled": {
                                                    color: mainColor,
                                                },
                                                "& .MuiFilledInput-underline:before": {
                                                    borderBottomColor: mainColor,
                                                },
                                                "& .MuiInputLabel-root": {
                                                    color: '#B3B3D4',
                                                    fontWeight: 'bold',
                                                    fontSize: '13px'
                                                },
                                                "& .MuiInputLabel-root.Mui-focused": {
                                                    color: mainColor,
                                                },
                                                "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                                    color: mainColor,
                                                },
                                                "& .MuiFilledInput-underline:hover:before": {
                                                    borderBottomColor: mainColor,
                                                },
                                                "& .MuiFilledInput-underline:hover:after": {
                                                    borderBottomColor: mainColor,
                                                },
                                                "& .MuiFilledInput-underline:focus": {
                                                    borderBottomColor: mainColor,
                                                },
                                                "& .MuiFilledInput-underline:focus:before": {
                                                    borderBottomColor: mainColor,
                                                },
                                                "& .MuiFilledInput-underline:focus:after": {
                                                    borderBottomColor: mainColor,
                                                },
                                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                                {
                                                    "-webkit-appearance": "none",
                                                    margin: 0,
                                                },
                                                "& input[type=number]": {
                                                    "-moz-appearance": "textfield",
                                                },
                                            }}
                                        />
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justifyContent: statusActivationEmail ? 'flex-start' : 'flex-end',
                                                width: '100%',
                                                marginTop: statusActivationEmail ? '10px' : '30px',
                                            }}
                                        >
                                            {statusActivationEmail ?
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Plus Jakarta Sans',
                                                        fontSize: '13px',
                                                        fontWeight: 'bold',
                                                        lineHeight: '21px',
                                                        color: 'green',
                                                    }}
                                                >
                                                    Verifikasi email berhasil,silahkan lanjut mengisi data dibawah ini.
                                                </Typography>
                                                :
                                                <Button
                                                    fullWidth
                                                    variant="outlined"
                                                    onClick={handleSendEmail}
                                                    size='large'
                                                    sx={{
                                                        dispaly: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        gap: '5px',
                                                        textTransform: 'none',
                                                        backgroundColor: bgFooter,
                                                        color: '#fff',
                                                        // fontFamily: 'Plus Jakarta Sans',
                                                        fontSize: '13px',
                                                        fontWeight: 'bold',
                                                        lineHeight: 'normal',
                                                        marginTop: '10px',
                                                        '&:hover': {
                                                            backgroundColor: bgFooter,
                                                        }
                                                    }}
                                                // disabled={isLoadingVerifikasi ? true : false}
                                                >
                                                    Verifikasi
                                                    <ForwardToInboxIcon style={{ fontSize: '20px' }} />
                                                </Button>
                                            }
                                        </Box>
                                    </Box>
                                    {!isMobile && (
                                        <CustomTooltip
                                            message='Pastikan email yang dimasukkan masih dalam keadaan aktif dan dapat menerima dan mengirim email.'
                                            padding='0px 8px'
                                        />
                                    )}
                                </Grid>

                                {statusActivationEmail ?
                                    <>
                                        {/* Upload Gambar KTP */}
                                        <Grid item xs={12} sm={12} mb='24px'>
                                            <Typography
                                                sx={{
                                                    color: '#1E222F',
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '14px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: 'normal',
                                                    marginBottom: '10px',
                                                    marginTop: '24px'
                                                }}
                                            >
                                                Foto Kartu Tanda Penduduk(KTP) (Maks 1 MB)
                                            </Typography>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    gap: '24px',
                                                    width: '100%',
                                                    // backgroundColor: 'orange'
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        maxWidth: '486px',
                                                        minWidth: '40%',
                                                        width: isMobile ? '100%' : '40%',
                                                        height: '190px',
                                                        backgroundColor: '#fff',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        gap: '12px',
                                                        position: 'relative',
                                                        border: `solid 2px ${DividerColor}`,
                                                        borderStyle: 'dashed',
                                                    }}
                                                    onDragOver={handleDragOverNIK}
                                                    onDrop={handleDropNIK}
                                                    onDragLeave={handleDragLeave}
                                                >
                                                    {isDragging.isDraggingNIK && !isMobile ?
                                                        <Typography
                                                            sx={{
                                                                fontFamily: 'Jakarta Sans, sans-serif',
                                                                fontSize: '13px',
                                                                fontStyle: 'normal',
                                                                fontWeight: 'bold',
                                                                lineHeight: '17,64px',
                                                                textAlign: 'center',
                                                                color: mainColor
                                                            }}
                                                        >
                                                            Lepaskan untuk mengunggah
                                                        </Typography>
                                                        :
                                                        selectedImageNIK && selectedImageNIK.type === 'application/pdf' ? (
                                                            // Handle PDF display logic
                                                            <>
                                                                {handleShowPDF(selectedImageNIK.name)}
                                                                <Box
                                                                    sx={{
                                                                        position: 'absolute',
                                                                        top: '4px',
                                                                        right: '4px',
                                                                        backgroundColor: '#fff',
                                                                        borderRadius: '50%',
                                                                        padding: '2px',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                    onClick={handleRemoveImageNIK}
                                                                >
                                                                    <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                                                </Box>
                                                            </>
                                                        ) : imageUrlNIK ? (
                                                            // Handle image display logic
                                                            <>
                                                                <img
                                                                    src={imageUrlNIK}
                                                                    alt="selected-image"
                                                                    style={{
                                                                        width: '100%',
                                                                        height: '100%',
                                                                        objectFit: 'contain',
                                                                        borderRadius: '4px',
                                                                    }}
                                                                />
                                                                <Box
                                                                    sx={{
                                                                        position: 'absolute',
                                                                        top: '4px',
                                                                        right: '4px',
                                                                        backgroundColor: '#fff',
                                                                        borderRadius: '50%',
                                                                        padding: '2px',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                    onClick={handleRemoveImageNIK}
                                                                >
                                                                    <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                                                </Box>
                                                            </>
                                                        ) : (
                                                            // Display upload options
                                                            <>
                                                                {!isMobile ?
                                                                    <>
                                                                        <Typography
                                                                            sx={{
                                                                                fontFamily: 'Jakarta Sans, sans-serif',
                                                                                fontSize: '13px',
                                                                                fontStyle: 'normal',
                                                                                fontWeight: '400',
                                                                                lineHeight: '17,64px',
                                                                                textAlign: 'center'
                                                                            }}
                                                                        >
                                                                            {selectedImageNIK ? 'Geser gambar ke area ini' : 'Geser gambar atau PDF ke area ini'}
                                                                        </Typography>
                                                                        <Typography
                                                                            sx={{
                                                                                fontFamily: 'Jakarta Sans, sans-serif',
                                                                                fontSize: '11px',
                                                                                fontStyle: 'normal',
                                                                                fontWeight: '400',
                                                                                lineHeight: '17,64px',
                                                                                color: TextGray
                                                                            }}
                                                                        >
                                                                            atau
                                                                        </Typography>
                                                                    </>
                                                                    :
                                                                    undefined
                                                                }
                                                                <div
                                                                    onClick={handleImageUploadNIK}
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                        padding: '6px 12px',
                                                                        border: '1px solid #ccc',
                                                                        borderRadius: '4px',
                                                                        display: 'inline-block',
                                                                        fontFamily: 'Jakarta Sans, sans-serif',
                                                                        fontSize: '12px',
                                                                        fontStyle: 'normal',
                                                                        fontWeight: '400',
                                                                        lineHeight: '17,64px',
                                                                        backgroundColor: bgColor
                                                                    }}
                                                                >
                                                                    Pilih {selectedImageNIK ? 'Gambar' : 'Gambar/PDF'}
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                    <input
                                                        type="file"
                                                        accept="image/*,.pdf"  // Accept both image and PDF files
                                                        onChange={(event) => handleFileInputChangeNIK(event.target.files[0])}
                                                        ref={fileInputRefNIK}
                                                        style={{ display: 'none' }}
                                                        disabled={!statusActivationEmail ? true : false}
                                                    />
                                                </Box>
                                                {!isMobile && (
                                                    <CustomTooltip
                                                        message='Pastikan Gambar yang dipilih dapat terbaca dengan jelas.'
                                                        padding='8px'
                                                    />
                                                )}
                                            </Box>
                                        </Grid>



                                        {/* Textfield KK */}
                                        <Grid item xs={12} md={12}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'flex-start',
                                                textAlign: 'center',
                                                gap: '24px'
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width: isMobile ? '100%' : '40%',
                                                    maxWidth: '40%',
                                                    minWidth: isMobile ? '100%' : '40%'
                                                }}
                                            >
                                                <TextField
                                                    fullWidth
                                                    variant="standard"
                                                    type="text"
                                                    label="Nomor Kartu Keluarga(KK)"
                                                    placeholder='Masukkan NIK'
                                                    value={newData.kk}
                                                    name="kk"
                                                    onChange={(e) => handleChange(e)}
                                                    onInput={(e) => {
                                                        // Filter out non-numeric characters
                                                        e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                                    }}
                                                    autoComplete="off"
                                                    required
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                {isMobile && (
                                                                    <Icon
                                                                        icon='ep:info-filled'
                                                                        onClick={() => setOpenPopperKK(true)}
                                                                        style={{ fontSize: '22px', cursor: 'pointer', color: mainColor }}
                                                                        ref={anchorRefKK}
                                                                    />
                                                                )}
                                                                <Popper
                                                                    open={openPopperKK}
                                                                    anchorEl={anchorRefKK.current}
                                                                    placement="top"
                                                                    transition
                                                                    // disablePortal
                                                                    modifiers={[{ name: 'flip', enabled: false }]}
                                                                    style={{ zIndex: 9999 }}
                                                                >
                                                                    {({ TransitionProps, placement }) => (
                                                                        <Grow
                                                                            {...TransitionProps}
                                                                            style={{
                                                                                transformOrigin:
                                                                                    placement === 'top-end' ? 'right bottom' : 'right top',
                                                                            }}
                                                                        >
                                                                            <Paper
                                                                                elevation={3}
                                                                                sx={{
                                                                                    padding: '5px',
                                                                                    margin: '0px 10px',
                                                                                }}
                                                                            >
                                                                                {/* Tooltip diletakkan di dalam ClickAwayListener */}
                                                                                <ClickAwayListener onClickAway={() => setOpenPopperKK(false)}>
                                                                                    <Tooltip title="Hanya menerima KK yang terdaftar sebagai penduduk Provinsi Papua Tengah." arrow>
                                                                                        {/* Children yang dibutuhkan oleh Tooltip (misalnya, title) */}
                                                                                        <Box>
                                                                                            <Typography
                                                                                                sx={{
                                                                                                    textAlign: 'center'
                                                                                                }}
                                                                                            >
                                                                                                Hanya menerima KK yang terdaftar sebagai penduduk Provinsi Papua Tengah.
                                                                                            </Typography>

                                                                                        </Box>
                                                                                    </Tooltip>
                                                                                </ClickAwayListener>
                                                                            </Paper>
                                                                        </Grow>
                                                                    )}
                                                                </Popper>

                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    sx={{
                                                        // marginBottom: isMobile ? '15px' : '14px',
                                                        fontSize: isMobile ? '14px' : '13px',
                                                        "& .MuiInputBase-input.Mui-disabled": {
                                                            WebkitTextFillColor: "white",
                                                        },
                                                        "& .MuiFilledInput-root.Mui-disabled:hover": {
                                                            "& fieldset": {
                                                                borderBottomColor: mainColor,
                                                            },
                                                        },
                                                        "& .MuiInputLabel-root.Mui-disabled": {
                                                            color: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:before": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& .MuiInputLabel-root": {
                                                            color: '#B3B3D4',
                                                            fontWeight: 'bold',
                                                            fontSize: '13px'
                                                        },
                                                        "& .MuiInputLabel-root.Mui-focused": {
                                                            color: mainColor,
                                                        },
                                                        "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                                            color: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:hover:before": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:hover:after": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:focus": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:focus:before": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:focus:after": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                                        {
                                                            "-webkit-appearance": "none",
                                                            margin: 0,
                                                        },
                                                        "& input[type=number]": {
                                                            "-moz-appearance": "textfield",
                                                        },
                                                    }}
                                                    disabled={!statusActivationEmail ? true : false}
                                                />
                                            </Box>
                                            {!isMobile && (
                                                <CustomTooltip
                                                    message='Hanya menerima KK yang terdaftar sebagai penduduk Provinsi Papua Tengah.'
                                                    padding='0px 8px'
                                                />
                                            )}
                                        </Grid>

                                        {/* Upload Gambar KK */}
                                        <Grid item xs={12} sm={12} >
                                            <Typography
                                                sx={{
                                                    color: '#1E222F',
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '14px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: 'normal',
                                                    marginBottom: '10px',
                                                    marginTop: '34px'
                                                }}
                                            >
                                                Foto Kartu Keluarga(KK) (Maks 1 MB)
                                            </Typography>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    gap: '24px',
                                                    width: '100%',
                                                    // backgroundColor: 'orange'
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        maxWidth: '486px',
                                                        minWidth: '40%',
                                                        width: isMobile ? '100%' : '40%',
                                                        height: '190px',
                                                        backgroundColor: '#fff',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        gap: '12px',
                                                        position: 'relative',
                                                        border: `solid 2px ${DividerColor}`,
                                                        borderStyle: 'dashed',
                                                    }}
                                                    onDragOver={handleDragOverKK}
                                                    onDragLeave={handleDragLeave}
                                                    onDrop={handleDropKK}
                                                >
                                                    {isDragging.isDraggingKK && !isMobile ?
                                                        <Typography
                                                            sx={{
                                                                fontFamily: 'Jakarta Sans, sans-serif',
                                                                fontSize: '13px',
                                                                fontStyle: 'normal',
                                                                fontWeight: 'bold',
                                                                lineHeight: '17,64px',
                                                                textAlign: 'center',
                                                                color: mainColor
                                                            }}
                                                        >
                                                            Lepaskan untuk mengunggah
                                                        </Typography>
                                                        :
                                                        selectedImageKK && selectedImageKK.type === 'application/pdf' ? (
                                                            // Handle PDF display logic
                                                            <>
                                                                {handleShowPDF(selectedImageKK.name)}
                                                                <Box
                                                                    sx={{
                                                                        position: 'absolute',
                                                                        top: '4px',
                                                                        right: '4px',
                                                                        backgroundColor: '#fff',
                                                                        borderRadius: '50%',
                                                                        padding: '2px',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                    onClick={handleRemoveImageKK}
                                                                >
                                                                    <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                                                </Box>
                                                            </>
                                                        ) : imageUrlKK ? (
                                                            // Handle image display logic
                                                            <>
                                                                <img
                                                                    src={imageUrlKK}
                                                                    alt="selected-image"
                                                                    style={{
                                                                        width: '100%',
                                                                        height: '100%',
                                                                        objectFit: 'contain',
                                                                        borderRadius: '4px',
                                                                    }}
                                                                />
                                                                <Box
                                                                    sx={{
                                                                        position: 'absolute',
                                                                        top: '4px',
                                                                        right: '4px',
                                                                        backgroundColor: '#fff',
                                                                        borderRadius: '50%',
                                                                        padding: '2px',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                    onClick={handleRemoveImageKK}
                                                                >
                                                                    <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                                                </Box>
                                                            </>
                                                        ) : (
                                                            // Display upload options
                                                            <>
                                                                {!isMobile ?
                                                                    <>
                                                                        <Typography
                                                                            sx={{
                                                                                fontFamily: 'Jakarta Sans, sans-serif',
                                                                                fontSize: '13px',
                                                                                fontStyle: 'normal',
                                                                                fontWeight: '400',
                                                                                lineHeight: '17,64px',
                                                                                textAlign: 'center'
                                                                            }}
                                                                        >
                                                                            {selectedImageKK ? 'Geser gambar ke area ini' : 'Geser gambar atau PDF ke area ini'}
                                                                        </Typography>
                                                                        <Typography
                                                                            sx={{
                                                                                fontFamily: 'Jakarta Sans, sans-serif',
                                                                                fontSize: '11px',
                                                                                fontStyle: 'normal',
                                                                                fontWeight: '400',
                                                                                lineHeight: '17,64px',
                                                                                color: TextGray
                                                                            }}
                                                                        >
                                                                            atau
                                                                        </Typography>
                                                                    </>
                                                                    :
                                                                    undefined
                                                                }
                                                                <div
                                                                    onClick={handleImageUploadKK}
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                        padding: '6px 12px',
                                                                        border: '1px solid #ccc',
                                                                        borderRadius: '4px',
                                                                        display: 'inline-block',
                                                                        fontFamily: 'Jakarta Sans, sans-serif',
                                                                        fontSize: '12px',
                                                                        fontStyle: 'normal',
                                                                        fontWeight: '400',
                                                                        lineHeight: '17,64px',
                                                                        backgroundColor: bgColor
                                                                    }}
                                                                >
                                                                    Pilih {selectedImageKK ? 'Gambar' : 'Gambar/PDF'}
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                    <input
                                                        type="file"
                                                        accept="image/*,.pdf"  // Accept both image and PDF files
                                                        onChange={(event) => handleFileInputChangeKK(event.target.files[0])}
                                                        ref={fileInputRefKK}
                                                        style={{ display: 'none' }}
                                                        disabled={!statusActivationEmail ? true : false}
                                                    />
                                                </Box>
                                                {!isMobile && (
                                                    <CustomTooltip
                                                        message='Pastikan Gambar yang dipilih dapat terbaca dengan jelas.'
                                                        padding='8px'
                                                    />
                                                )}
                                            </Box>
                                        </Grid>

                                        {/* Asal Kabupaten */}
                                        <Grid item xs={12} md={12}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'flex-start',
                                                textAlign: 'flex-start',
                                                gap: '24px',
                                                marginTop: '24px'
                                                // marginBottom: '24px'
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width: isMobile ? '100%' : '40%',
                                                    maxWidth: '40%',
                                                    minWidth: isMobile ? '100%' : '40%'
                                                }}
                                            >
                                                <FormControl required variant="standard" fullWidth>
                                                    <InputLabel id="asal-kabupaten-labelId">Asal Kabupaten</InputLabel>
                                                    <Select
                                                        label="Asal Kabupaten"
                                                        labelId="asal-kabupaten-labelId"
                                                        id="asal-kabupaten-Id"
                                                        defaultValue=''
                                                        value={asalKabupaten}
                                                        onChange={(e) => handleSelectKabupaten(e)}
                                                        required
                                                        disabled={!statusActivationEmail ? true : false}
                                                    >
                                                        {listKabupaten && listKabupaten.map((kabupaten, index) => (
                                                            <MenuItem key={index} value={kabupaten}>
                                                                {kabupaten}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                            {/* {!isMobile && (
                        <CustomTooltip
                            message='Pastikan memasukkan nama Ayah sesuai dengan KTP dan Kartu Keluarga.'
                            padding='0px 8px'
                        />
                    )} */}
                                        </Grid>

                                        {/* Tempat Lahir */}
                                        <Grid item xs={12} md={12}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'flex-start',
                                                textAlign: 'center',
                                                gap: '24px',
                                                marginTop: '24px'
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width: isMobile ? '100%' : '40%',
                                                    maxWidth: '40%',
                                                    minWidth: isMobile ? '100%' : '40%'
                                                }}
                                            >
                                                <TextField
                                                    fullWidth
                                                    variant="standard"
                                                    type="text"
                                                    label="Tempat Lahir"
                                                    placeholder='Masukkan tempat lahir'
                                                    value={newData.tempatLahir}
                                                    name="tempatLahir"
                                                    onChange={(e) => handleChange(e)}
                                                    // onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                                                    autoComplete="off"
                                                    required
                                                    disabled={!statusActivationEmail ? true : false}
                                                    sx={{
                                                        // marginBottom: isMobile ? '24px' : '24px',
                                                        fontSize: isMobile ? '14px' : '13px',
                                                        "& .MuiInputBase-input.Mui-disabled": {
                                                            WebkitTextFillColor: "white",
                                                        },
                                                        "& .MuiFilledInput-root.Mui-disabled:hover": {
                                                            "& fieldset": {
                                                                borderBottomColor: mainColor,
                                                            },
                                                        },
                                                        "& .MuiInputLabel-root.Mui-disabled": {
                                                            color: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:before": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& .MuiInputLabel-root": {
                                                            color: '#B3B3D4',
                                                            fontWeight: 'bold',
                                                            fontSize: '13px'
                                                        },
                                                        "& .MuiInputLabel-root.Mui-focused": {
                                                            color: mainColor,
                                                        },
                                                        "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                                            color: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:hover:before": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:hover:after": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:focus": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:focus:before": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:focus:after": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                                        {
                                                            "-webkit-appearance": "none",
                                                            margin: 0,
                                                        },
                                                        "& input[type=number]": {
                                                            "-moz-appearance": "textfield",
                                                        },
                                                    }}
                                                />
                                            </Box>
                                            {/* {!isMobile && (
                        <CustomTooltip
                            message='Pastikan memasukkan nama Ayah sesuai dengan KTP dan Kartu Keluarga.'
                            padding='0px 8px'
                        />
                    )} */}
                                        </Grid>

                                        {/* Tanggal Lahir */}
                                        <Grid item xs={12} md={12}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'flex-start',
                                                textAlign: 'flex-start',
                                                gap: '24px',
                                                marginTop: '28px',
                                                marginBottom: '24px'
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width: isMobile ? '100%' : '40%',
                                                    maxWidth: '40%',
                                                    minWidth: isMobile ? '100%' : '40%'
                                                }}
                                            >
                                                <DatePicker
                                                    label='Tanggal Lahir'
                                                    // localeText={{ toolbarTitle: 'Title' }}
                                                    sx={{ padding: '0px', marginTop: '5px', width: '100%' }}
                                                    slotProps={{
                                                        textField: { variant: 'standard' },
                                                        toolbar: {
                                                            toolbarPlaceholder: '__',
                                                            toolbarFormat: 'yyyy-mm-dd',
                                                            hidden: true,
                                                        },
                                                    }}
                                                    // slots={{
                                                    //   toolbar: CustomToolbarComponent
                                                    // }}
                                                    value={monthAndYear}
                                                    onChange={(newValue) => setMonthAndYear(newValue)}
                                                    disabled={!statusActivationEmail ? true : false}
                                                />
                                            </Box>
                                            {/* {!isMobile && (
                        <CustomTooltip
                            message='Pastikan memasukkan nama Ayah sesuai dengan KTP dan Kartu Keluarga.'
                            padding='0px 8px'
                        />
                    )} */}
                                        </Grid>

                                        {/* Textfield Father Fullname */}
                                        <Grid item xs={12} md={12}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'flex-start',
                                                textAlign: 'center',
                                                gap: '24px'
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width: isMobile ? '100%' : '40%',
                                                    maxWidth: '40%',
                                                    minWidth: isMobile ? '100%' : '40%'
                                                }}
                                            >
                                                <TextField
                                                    fullWidth
                                                    variant="standard"
                                                    type="text"
                                                    label="Nama Ayah(Sesuai KTP)"
                                                    placeholder='Masukkan nama Ayah'
                                                    value={newData.daddyFullName}
                                                    name="daddyFullName"
                                                    onChange={(e) => handleChange(e)}
                                                    autoComplete="off"
                                                    required
                                                    disabled={!statusActivationEmail ? true : false}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                {isMobile && (
                                                                    <Icon
                                                                        icon='ep:info-filled'
                                                                        onClick={() => setOpenPopperDaddy(true)}
                                                                        style={{ fontSize: '22px', cursor: 'pointer', color: mainColor }}
                                                                        ref={anchorRefDaddy}
                                                                    />
                                                                )}
                                                                <Popper
                                                                    open={openPopperDaddy}
                                                                    anchorEl={anchorRefDaddy.current}
                                                                    placement="top"
                                                                    transition
                                                                    // disablePortal
                                                                    modifiers={[{ name: 'flip', enabled: false }]}
                                                                    style={{ zIndex: 9999 }}
                                                                >
                                                                    {({ TransitionProps, placement }) => (
                                                                        <Grow
                                                                            {...TransitionProps}
                                                                            style={{
                                                                                transformOrigin:
                                                                                    placement === 'top-end' ? 'right bottom' : 'right top',
                                                                            }}
                                                                        >
                                                                            <Paper
                                                                                elevation={3}
                                                                                sx={{
                                                                                    padding: '5px',
                                                                                    margin: '0px 10px',
                                                                                }}
                                                                            >
                                                                                {/* Tooltip diletakkan di dalam ClickAwayListener */}
                                                                                <ClickAwayListener onClickAway={() => setOpenPopperDaddy(false)}>
                                                                                    <Tooltip title="Pastikan memasukkan nama Ayah sesuai dengan KTP dan Kartu Keluarga." arrow>
                                                                                        {/* Children yang dibutuhkan oleh Tooltip (misalnya, title) */}
                                                                                        <Box>
                                                                                            <Typography
                                                                                                sx={{
                                                                                                    textAlign: 'center'
                                                                                                }}
                                                                                            >
                                                                                                Pastikan memasukkan nama Ayah sesuai dengan KTP dan Kartu Keluarga.
                                                                                            </Typography>

                                                                                        </Box>
                                                                                    </Tooltip>
                                                                                </ClickAwayListener>
                                                                            </Paper>
                                                                        </Grow>
                                                                    )}
                                                                </Popper>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    sx={{
                                                        marginBottom: '24px',
                                                        fontSize: isMobile ? '14px' : '13px',
                                                        "& .MuiInputBase-input.Mui-disabled": {
                                                            WebkitTextFillColor: "white",
                                                        },
                                                        "& .MuiFilledInput-root.Mui-disabled:hover": {
                                                            "& fieldset": {
                                                                borderBottomColor: mainColor,
                                                            },
                                                        },
                                                        "& .MuiInputLabel-root.Mui-disabled": {
                                                            color: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:before": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& .MuiInputLabel-root": {
                                                            color: '#B3B3D4',
                                                            fontWeight: 'bold',
                                                            fontSize: '13px'
                                                        },
                                                        "& .MuiInputLabel-root.Mui-focused": {
                                                            color: mainColor,
                                                        },
                                                        "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                                            color: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:hover:before": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:hover:after": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:focus": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:focus:before": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:focus:after": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                                        {
                                                            "-webkit-appearance": "none",
                                                            margin: 0,
                                                        },
                                                        "& input[type=number]": {
                                                            "-moz-appearance": "textfield",
                                                        },
                                                    }}
                                                />
                                            </Box>
                                            {!isMobile && (
                                                <CustomTooltip
                                                    message='Pastikan memasukkan nama Ayah sesuai dengan KTP dan Kartu Keluarga.'
                                                    padding='0px 8px'
                                                />
                                            )}
                                        </Grid>

                                        <Grid item xs={12} md={12}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'flex-start',
                                                textAlign: 'center',
                                                gap: '24px'
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width: isMobile ? '100%' : '40%',
                                                    maxWidth: '40%',
                                                    minWidth: isMobile ? '100%' : '40%'
                                                }}
                                            >
                                                <TextField
                                                    fullWidth
                                                    variant="standard"
                                                    type="text"
                                                    label="Nomor KTP Ayah"
                                                    placeholder='Masukkan nomor KTP Ayah'
                                                    value={newData.nikAyah}
                                                    name="nikAyah"
                                                    onChange={(e) => handleChange(e)}
                                                    onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                                                    autoComplete="off"
                                                    error={newData.nikAyah === '' ? undefined : !!KTPAyahError}
                                                    helperText={KTPAyahError}
                                                    required
                                                    disabled={!statusActivationEmail ? true : false}
                                                    sx={{
                                                        marginBottom: isMobile ? '24px' : '34px',
                                                        fontSize: isMobile ? '14px' : '13px',
                                                        "& .MuiInputBase-input.Mui-disabled": {
                                                            WebkitTextFillColor: "white",
                                                        },
                                                        "& .MuiFilledInput-root.Mui-disabled:hover": {
                                                            "& fieldset": {
                                                                borderBottomColor: mainColor,
                                                            },
                                                        },
                                                        "& .MuiInputLabel-root.Mui-disabled": {
                                                            color: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:before": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& .MuiInputLabel-root": {
                                                            color: '#B3B3D4',
                                                            fontWeight: 'bold',
                                                            fontSize: '13px'
                                                        },
                                                        "& .MuiInputLabel-root.Mui-focused": {
                                                            color: mainColor,
                                                        },
                                                        "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                                            color: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:hover:before": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:hover:after": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:focus": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:focus:before": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:focus:after": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                                        {
                                                            "-webkit-appearance": "none",
                                                            margin: 0,
                                                        },
                                                        "& input[type=number]": {
                                                            "-moz-appearance": "textfield",
                                                        },
                                                    }}
                                                />
                                            </Box>
                                            {/* {!isMobile && (
                        <CustomTooltip
                            message='Pastikan memasukkan nama Ayah sesuai dengan KTP dan Kartu Keluarga.'
                            padding='0px 8px'
                        />
                    )} */}
                                        </Grid>

                                        {/* Textfiled Mother Fullname */}
                                        <Grid item xs={12} md={12}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'flex-start',
                                                textAlign: 'center',
                                                gap: '24px'
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width: isMobile ? '100%' : '40%',
                                                    maxWidth: '40%',
                                                    minWidth: isMobile ? '100%' : '40%'
                                                }}
                                            >
                                                <TextField
                                                    fullWidth
                                                    variant="standard"
                                                    type="text"
                                                    label="Nama Ibu(Sesuai KTP)"
                                                    placeholder='Masukkan nama Ibu'
                                                    value={newData.mommyFullName}
                                                    name="mommyFullName"
                                                    onChange={(e) => handleChange(e)}
                                                    autoComplete="off"
                                                    disabled={!statusActivationEmail ? true : false}
                                                    required
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                {isMobile && (
                                                                    <Icon
                                                                        icon='ep:info-filled'
                                                                        onClick={() => setOpenPopperMommy(true)}
                                                                        style={{ fontSize: '22px', cursor: 'pointer', color: mainColor }}
                                                                        ref={anchorRefMommy}
                                                                    />
                                                                )}
                                                                <Popper
                                                                    open={openPopperMommy}
                                                                    anchorEl={anchorRefMommy.current}
                                                                    placement="top"
                                                                    transition
                                                                    // disablePortal
                                                                    modifiers={[{ name: 'flip', enabled: false }]}
                                                                    style={{ zIndex: 9999 }}
                                                                >
                                                                    {({ TransitionProps, placement }) => (
                                                                        <Grow
                                                                            {...TransitionProps}
                                                                            style={{
                                                                                transformOrigin:
                                                                                    placement === 'top-end' ? 'right bottom' : 'right top',
                                                                            }}
                                                                        >
                                                                            <Paper
                                                                                elevation={3}
                                                                                sx={{
                                                                                    padding: '5px',
                                                                                    margin: '0px 10px',
                                                                                }}
                                                                            >
                                                                                {/* Tooltip diletakkan di dalam ClickAwayListener */}
                                                                                <ClickAwayListener onClickAway={() => setOpenPopperMommy(false)}>
                                                                                    <Tooltip title="Pastikan memasukkan nama Ibu sesuai dengan KTP dan Kartu Keluarga." arrow>
                                                                                        {/* Children yang dibutuhkan oleh Tooltip (misalnya, title) */}
                                                                                        <Box>
                                                                                            <Typography
                                                                                                sx={{
                                                                                                    textAlign: 'center'
                                                                                                }}
                                                                                            >
                                                                                                Pastikan memasukkan nama Ibu sesuai dengan KTP dan Kartu Keluarga
                                                                                            </Typography>

                                                                                        </Box>
                                                                                    </Tooltip>
                                                                                </ClickAwayListener>
                                                                            </Paper>
                                                                        </Grow>
                                                                    )}
                                                                </Popper>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    sx={{
                                                        marginBottom: isMobile ? '24px' : '34px',
                                                        fontSize: isMobile ? '14px' : '13px',
                                                        "& .MuiInputBase-input.Mui-disabled": {
                                                            WebkitTextFillColor: "white",
                                                        },
                                                        "& .MuiFilledInput-root.Mui-disabled:hover": {
                                                            "& fieldset": {
                                                                borderBottomColor: mainColor,
                                                            },
                                                        },
                                                        "& .MuiInputLabel-root.Mui-disabled": {
                                                            color: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:before": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& .MuiInputLabel-root": {
                                                            color: '#B3B3D4',
                                                            fontWeight: 'bold',
                                                            fontSize: '13px'
                                                        },
                                                        "& .MuiInputLabel-root.Mui-focused": {
                                                            color: mainColor,
                                                        },
                                                        "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                                            color: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:hover:before": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:hover:after": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:focus": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:focus:before": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:focus:after": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                                        {
                                                            "-webkit-appearance": "none",
                                                            margin: 0,
                                                        },
                                                        "& input[type=number]": {
                                                            "-moz-appearance": "textfield",
                                                        },
                                                    }}
                                                />
                                            </Box>
                                            {!isMobile && (
                                                <CustomTooltip
                                                    message='Pastikan memasukkan nama Ibu sesuai dengan KTP dan Kartu Keluarga.'
                                                    padding='0px 8px'
                                                />
                                            )}
                                        </Grid>

                                        <Grid item xs={12} md={12}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'flex-start',
                                                textAlign: 'center',
                                                gap: '24px'
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width: isMobile ? '100%' : '40%',
                                                    maxWidth: '40%',
                                                    minWidth: isMobile ? '100%' : '40%'
                                                }}
                                            >
                                                <TextField
                                                    fullWidth
                                                    variant="standard"
                                                    type="text"
                                                    label="Nomor KTP Ibu"
                                                    placeholder='Masukkan nomor KTP Ibu'
                                                    value={newData.nikIbu}
                                                    name="nikIbu"
                                                    onChange={(e) => handleChange(e)}
                                                    onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                                                    autoComplete="off"
                                                    error={newData.nikIbu === '' ? undefined : !!KTPIbuError}
                                                    helperText={KTPIbuError}
                                                    disabled={!statusActivationEmail ? true : false}
                                                    required
                                                    sx={{
                                                        marginBottom: isMobile ? '24px' : '34px',
                                                        fontSize: isMobile ? '14px' : '13px',
                                                        "& .MuiInputBase-input.Mui-disabled": {
                                                            WebkitTextFillColor: "white",
                                                        },
                                                        "& .MuiFilledInput-root.Mui-disabled:hover": {
                                                            "& fieldset": {
                                                                borderBottomColor: mainColor,
                                                            },
                                                        },
                                                        "& .MuiInputLabel-root.Mui-disabled": {
                                                            color: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:before": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& .MuiInputLabel-root": {
                                                            color: '#B3B3D4',
                                                            fontWeight: 'bold',
                                                            fontSize: '13px'
                                                        },
                                                        "& .MuiInputLabel-root.Mui-focused": {
                                                            color: mainColor,
                                                        },
                                                        "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                                            color: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:hover:before": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:hover:after": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:focus": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:focus:before": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:focus:after": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                                        {
                                                            "-webkit-appearance": "none",
                                                            margin: 0,
                                                        },
                                                        "& input[type=number]": {
                                                            "-moz-appearance": "textfield",
                                                        },
                                                    }}
                                                />
                                            </Box>
                                            {/* {!isMobile && (
                        <CustomTooltip
                            message='Pastikan memasukkan nama Ayah sesuai dengan KTP dan Kartu Keluarga.'
                            padding='0px 8px'
                        />
                    )} */}
                                        </Grid>

                                        {/* Textfield Nomor Whatsapp */}
                                        <Grid item xs={12} md={12}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'flex-start',
                                                textAlign: 'center',
                                                gap: '24px'
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width: isMobile ? '100%' : '40%',
                                                    maxWidth: '40%',
                                                    minWidth: isMobile ? '100%' : '40%'
                                                }}
                                            >
                                                <TextField
                                                    fullWidth
                                                    variant="standard"
                                                    type="text"
                                                    label="Nomor Whatsapp"
                                                    placeholder='Masukkan nomor Whatsapp'
                                                    value={newData.whatsapp}
                                                    name="whatsapp"
                                                    onChange={(e) => handleChange(e)}
                                                    onInput={(e) => {
                                                        // Filter out non-numeric characters
                                                        e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 15);
                                                    }}
                                                    autoComplete="off"
                                                    disabled={!statusActivationEmail ? true : false}
                                                    required
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                {isMobile && (
                                                                    <Icon
                                                                        icon='ep:info-filled'
                                                                        onClick={() => setOpenPopperWhatsapp(true)}
                                                                        style={{ fontSize: '22px', cursor: 'pointer', color: mainColor }}
                                                                        ref={anchorRefWhatsapp}
                                                                    />
                                                                )}
                                                                <Popper
                                                                    open={openPopperWhatsapp}
                                                                    anchorEl={anchorRefWhatsapp.current}
                                                                    placement="top"
                                                                    transition
                                                                    // disablePortal
                                                                    modifiers={[{ name: 'flip', enabled: false }]}
                                                                    style={{ zIndex: 9999 }}
                                                                >
                                                                    {({ TransitionProps, placement }) => (
                                                                        <Grow
                                                                            {...TransitionProps}
                                                                            style={{
                                                                                transformOrigin:
                                                                                    placement === 'top-end' ? 'right bottom' : 'right top',
                                                                            }}
                                                                        >
                                                                            <Paper
                                                                                elevation={3}
                                                                                sx={{
                                                                                    padding: '5px',
                                                                                    margin: '0px 10px',
                                                                                }}
                                                                            >
                                                                                {/* Tooltip diletakkan di dalam ClickAwayListener */}
                                                                                <ClickAwayListener onClickAway={() => setOpenPopperWhatsapp(false)}>
                                                                                    <Tooltip title="Pastikan nomor yang dimasukkan aktif serta dapat menerima dan melakukan panggilan." arrow>
                                                                                        {/* Children yang dibutuhkan oleh Tooltip (misalnya, title) */}
                                                                                        <Box>
                                                                                            <Typography
                                                                                                sx={{
                                                                                                    textAlign: 'center',
                                                                                                }}
                                                                                            >
                                                                                                Pastikan nomor yang dimasukkan aktif serta dapat menerima dan melakukan panggilan.
                                                                                            </Typography>
                                                                                        </Box>
                                                                                    </Tooltip>
                                                                                </ClickAwayListener>
                                                                            </Paper>
                                                                        </Grow>
                                                                    )}
                                                                </Popper>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    sx={{
                                                        marginBottom: isMobile ? '24px' : '34px',
                                                        fontSize: isMobile ? '14px' : '13px',
                                                        "& .MuiInputBase-input.Mui-disabled": {
                                                            WebkitTextFillColor: "white",
                                                        },
                                                        "& .MuiFilledInput-root.Mui-disabled:hover": {
                                                            "& fieldset": {
                                                                borderBottomColor: mainColor,
                                                            },
                                                        },
                                                        "& .MuiInputLabel-root.Mui-disabled": {
                                                            color: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:before": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& .MuiInputLabel-root": {
                                                            color: '#B3B3D4',
                                                            fontWeight: 'bold',
                                                            fontSize: '13px'
                                                        },
                                                        "& .MuiInputLabel-root.Mui-focused": {
                                                            color: mainColor,
                                                        },
                                                        "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                                            color: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:hover:before": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:hover:after": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:focus": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:focus:before": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:focus:after": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                                        {
                                                            "-webkit-appearance": "none",
                                                            margin: 0,
                                                        },
                                                        "& input[type=number]": {
                                                            "-moz-appearance": "textfield",
                                                        },
                                                    }}
                                                />
                                            </Box>
                                            {!isMobile && (
                                                <CustomTooltip
                                                    message='Pastikan nomor yang dimasukkan aktif serta dapat menerima dan melakukan panggilan.'
                                                    padding='0px 8px'
                                                />
                                            )}
                                        </Grid>

                                        {/* Textfield Nomor Whatsapp Orangtua/wali */}
                                        <Grid item xs={12} md={12}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'flex-start',
                                                textAlign: 'center',
                                                gap: '24px'
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width: isMobile ? '100%' : '40%',
                                                    maxWidth: '40%',
                                                    minWidth: isMobile ? '100%' : '40%'
                                                }}
                                            >
                                                <TextField
                                                    fullWidth
                                                    variant="standard"
                                                    type="text"
                                                    label="Nomor Whatsapp Orangtua/Wali"
                                                    placeholder='Masukkan nomor whatsapp orangtua/wali'
                                                    value={newData.whatsappOrangtua}
                                                    name="whatsappOrangtua"
                                                    onChange={(e) => handleChange(e)}
                                                    onInput={(e) => {
                                                        // Filter out non-numeric characters
                                                        e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 15);
                                                    }}
                                                    autoComplete="off"
                                                    disabled={!statusActivationEmail ? true : false}
                                                    required
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                {isMobile && (
                                                                    <Icon
                                                                        icon='ep:info-filled'
                                                                        onClick={() => setOpenPopperWhatsapp(true)}
                                                                        style={{ fontSize: '22px', cursor: 'pointer', color: mainColor }}
                                                                        ref={anchorRefWhatsapp}
                                                                    />
                                                                )}
                                                                <Popper
                                                                    open={openPopperWhatsapp}
                                                                    anchorEl={anchorRefWhatsapp.current}
                                                                    placement="top"
                                                                    transition
                                                                    // disablePortal
                                                                    modifiers={[{ name: 'flip', enabled: false }]}
                                                                    style={{ zIndex: 9999 }}
                                                                >
                                                                    {({ TransitionProps, placement }) => (
                                                                        <Grow
                                                                            {...TransitionProps}
                                                                            style={{
                                                                                transformOrigin:
                                                                                    placement === 'top-end' ? 'right bottom' : 'right top',
                                                                            }}
                                                                        >
                                                                            <Paper
                                                                                elevation={3}
                                                                                sx={{
                                                                                    padding: '5px',
                                                                                    margin: '0px 10px',
                                                                                }}
                                                                            >
                                                                                {/* Tooltip diletakkan di dalam ClickAwayListener */}
                                                                                <ClickAwayListener onClickAway={() => setOpenPopperWhatsapp(false)}>
                                                                                    <Tooltip title="Pastikan nomor yang dimasukkan aktif serta dapat menerima dan melakukan panggilan." arrow>
                                                                                        {/* Children yang dibutuhkan oleh Tooltip (misalnya, title) */}
                                                                                        <Box>
                                                                                            <Typography
                                                                                                sx={{
                                                                                                    textAlign: 'center',
                                                                                                }}
                                                                                            >
                                                                                                Pastikan nomor yang dimasukkan aktif serta dapat menerima dan melakukan panggilan.
                                                                                            </Typography>
                                                                                        </Box>
                                                                                    </Tooltip>
                                                                                </ClickAwayListener>
                                                                            </Paper>
                                                                        </Grow>
                                                                    )}
                                                                </Popper>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    sx={{
                                                        marginBottom: isMobile ? '24px' : '34px',
                                                        fontSize: isMobile ? '14px' : '13px',
                                                        "& .MuiInputBase-input.Mui-disabled": {
                                                            WebkitTextFillColor: "white",
                                                        },
                                                        "& .MuiFilledInput-root.Mui-disabled:hover": {
                                                            "& fieldset": {
                                                                borderBottomColor: mainColor,
                                                            },
                                                        },
                                                        "& .MuiInputLabel-root.Mui-disabled": {
                                                            color: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:before": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& .MuiInputLabel-root": {
                                                            color: '#B3B3D4',
                                                            fontWeight: 'bold',
                                                            fontSize: '13px'
                                                        },
                                                        "& .MuiInputLabel-root.Mui-focused": {
                                                            color: mainColor,
                                                        },
                                                        "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                                            color: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:hover:before": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:hover:after": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:focus": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:focus:before": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:focus:after": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                                        {
                                                            "-webkit-appearance": "none",
                                                            margin: 0,
                                                        },
                                                        "& input[type=number]": {
                                                            "-moz-appearance": "textfield",
                                                        },
                                                    }}
                                                />
                                            </Box>
                                            {!isMobile && (
                                                <CustomTooltip
                                                    message='Pastikan nomor yang dimasukkan aktif serta dapat menerima dan melakukan panggilan.'
                                                    padding='0px 8px'
                                                />
                                            )}
                                        </Grid>

                                        <Grid item xs={12} md={12}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'flex-start',
                                                textAlign: 'center',
                                                gap: '24px'
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width: isMobile ? '100%' : '40%',
                                                    maxWidth: '40%',
                                                    minWidth: isMobile ? '100%' : '40%'
                                                }}
                                            >
                                                <TextField
                                                    fullWidth
                                                    variant="standard"
                                                    type={showPassword ? 'text' : 'password'}
                                                    label="Kata Sandi"
                                                    placeholder='Masukkan kata sandi'
                                                    value={newData.password}
                                                    name="password"
                                                    onChange={(e) => handleChange(e)}
                                                    autoComplete="off"
                                                    error={newData.password === '' ? undefined : !!passwordError}
                                                    helperText={passwordError}
                                                    autoFocus={autoFocus.password}
                                                    disabled={!statusActivationEmail ? true : false}
                                                    required
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <Icon
                                                                    onClick={handleClickShowPassword}
                                                                    icon={showPassword ? 'streamline:eye-optic' : 'humbleicons:eye-close'}
                                                                    // onClick={() => setOpenPopperNIK(true)}
                                                                    style={{
                                                                        fontSize: '22px',
                                                                        cursor: 'pointer',
                                                                        color: mainColor,
                                                                        marginRight: isMobile ? '15px' : '0px',
                                                                    }}
                                                                // ref={anchorRefNIK}
                                                                />
                                                                {isMobile && (
                                                                    <Icon
                                                                        icon='ep:info-filled'
                                                                        onClick={() => setOpenPopperPassword(true)}
                                                                        style={{ fontSize: '22px', cursor: 'pointer', color: mainColor }}
                                                                        ref={anchorRefKatasandi}
                                                                    />
                                                                )}
                                                                <Popper
                                                                    open={openPopperPassword}
                                                                    anchorEl={anchorRefKatasandi.current}
                                                                    placement="top"
                                                                    transition
                                                                    // disablePortal
                                                                    modifiers={[{ name: 'flip', enabled: false }]}
                                                                    style={{ zIndex: 9999 }}
                                                                >
                                                                    {({ TransitionProps, placement }) => (
                                                                        <Grow
                                                                            {...TransitionProps}
                                                                            style={{
                                                                                transformOrigin:
                                                                                    placement === 'top-end' ? 'right bottom' : 'right top',
                                                                            }}
                                                                        >
                                                                            <Paper
                                                                                elevation={3}
                                                                                sx={{
                                                                                    padding: '5px',
                                                                                    margin: '0px 10px',
                                                                                }}
                                                                            >
                                                                                {/* Tooltip diletakkan di dalam ClickAwayListener */}
                                                                                <ClickAwayListener onClickAway={() => setOpenPopperPassword(false)}>
                                                                                    <Tooltip title="Gunakan simbol, angka, gabungan huruf besar/kecil untuk keamanan kata sandi." arrow>
                                                                                        {/* Children yang dibutuhkan oleh Tooltip (misalnya, title) */}
                                                                                        <Box>
                                                                                            <Typography
                                                                                                sx={{
                                                                                                    textAlign: 'center'
                                                                                                }}
                                                                                            >
                                                                                                Gunakan simbol, angka, gabungan huruf besar/kecil untuk keamanan kata sandi.
                                                                                            </Typography>

                                                                                        </Box>
                                                                                    </Tooltip>
                                                                                </ClickAwayListener>
                                                                            </Paper>
                                                                        </Grow>
                                                                    )}
                                                                </Popper>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    sx={{
                                                        marginBottom: isMobile ? '24px' : '34px',
                                                        fontSize: isMobile ? '14px' : '13px',
                                                        "& .MuiInputBase-input.Mui-disabled": {
                                                            WebkitTextFillColor: "white",
                                                        },
                                                        "& .MuiFilledInput-root.Mui-disabled:hover": {
                                                            "& fieldset": {
                                                                borderBottomColor: mainColor,
                                                            },
                                                        },
                                                        "& .MuiInputLabel-root.Mui-disabled": {
                                                            color: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:before": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& .MuiInputLabel-root": {
                                                            color: '#B3B3D4',
                                                            fontWeight: 'bold',
                                                            fontSize: '13px'
                                                        },
                                                        "& .MuiInputLabel-root.Mui-focused": {
                                                            color: mainColor,
                                                        },
                                                        "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                                            color: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:hover:before": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:hover:after": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:focus": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:focus:before": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:focus:after": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                                        {
                                                            "-webkit-appearance": "none",
                                                            margin: 0,
                                                        },
                                                        "& input[type=number]": {
                                                            "-moz-appearance": "textfield",
                                                        },
                                                    }}
                                                    inputProps={{
                                                        maxLength: 20, // maksimal 20 karakter
                                                        minLength: 4, // minimal 8 karakter
                                                    }}
                                                />
                                            </Box>
                                            {!isMobile && (
                                                <CustomTooltip
                                                    message='Gunakan simbol, angka, gabungan huruf besar/kecil untuk keamanan kata sandi.'
                                                    padding='0px 8px'
                                                />
                                            )}
                                        </Grid>

                                        {/* Ulangi Kata Sandi */}
                                        <Grid item xs={12} md={12}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'flex-start',
                                                textAlign: 'center',
                                                gap: '24px'
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width: isMobile ? '100%' : '40%',
                                                    maxWidth: '40%',
                                                    minWidth: isMobile ? '100%' : '40%'
                                                }}
                                            >
                                                <TextField
                                                    fullWidth
                                                    variant="standard"
                                                    type={showConfirmationPassword ? 'text' : 'password'}
                                                    label="Ulangi Kata Sandi"
                                                    placeholder='Masukkan kata sandi'
                                                    value={newData.konfirmasiPassword}
                                                    name="konfirmasiPassword"
                                                    onChange={(e) => handleChange(e)}
                                                    autoComplete="off"
                                                    error={newData.konfirmasiPassword === '' ? undefined : !!konfirmasiPassword}
                                                    helperText={konfirmasiPassword}
                                                    disabled={!statusActivationEmail ? true : false}
                                                    required
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <Icon
                                                                    onClick={handleClickShowConfirmationPassword}
                                                                    icon={showConfirmationPassword ? 'streamline:eye-optic' : 'humbleicons:eye-close'}
                                                                    // onClick={() => setOpenPopperNIK(true)}
                                                                    style={{ fontSize: '22px', cursor: 'pointer', color: mainColor }}
                                                                // ref={anchorRefNIK}
                                                                />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    sx={{
                                                        marginBottom: isMobile ? '10px' : '15px',
                                                        fontSize: isMobile ? '14px' : '13px',
                                                        "& .MuiInputBase-input.Mui-disabled": {
                                                            WebkitTextFillColor: "white",
                                                        },
                                                        "& .MuiFilledInput-root.Mui-disabled:hover": {
                                                            "& fieldset": {
                                                                borderBottomColor: mainColor,
                                                            },
                                                        },
                                                        "& .MuiInputLabel-root.Mui-disabled": {
                                                            color: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:before": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& .MuiInputLabel-root": {
                                                            color: '#B3B3D4',
                                                            fontWeight: 'bold',
                                                            fontSize: '13px',
                                                        },
                                                        "& .MuiInputLabel-root.Mui-focused": {
                                                            color: mainColor,
                                                        },
                                                        "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                                            color: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:hover:before": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:hover:after": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:focus": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:focus:before": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& .MuiFilledInput-underline:focus:after": {
                                                            borderBottomColor: mainColor,
                                                        },
                                                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                                        {
                                                            "-webkit-appearance": "none",
                                                            margin: 0,
                                                        },
                                                        "& input[type=number]": {
                                                            "-moz-appearance": "textfield",
                                                        },
                                                    }}
                                                />
                                            </Box>
                                        </Grid>

                                        {/* Button Save */}
                                        <Grid xs={12} md={12}
                                            sx={{
                                                marginBottom: isMobile ? '36px' : undefined,
                                                marginTop: isMobile ? '20px' : '26px'
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width: isMobile ? '100%' : '40%',
                                                    maxWidth: '40%',
                                                    minWidth: isMobile ? '100%' : '40%',
                                                }}
                                            >
                                                <Button
                                                    fullWidth
                                                    variant='contained'
                                                    size='medium'
                                                    type='submit'
                                                    sx={{
                                                        textTransform: 'none',
                                                        backgroundColor: btnBgColor,
                                                        color: mainColor,
                                                        fontWeight: 'bold',
                                                        '&:hover': {
                                                            color: 'white', // Change text color to white on hover
                                                            backgroundColor: mainColor,
                                                        },
                                                    }}
                                                // disabled={loadingSimpanData ? true : false}
                                                >
                                                    {loadingSimpanData ?
                                                        <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px' }} />
                                                        :
                                                        'Simpan Data'
                                                    }
                                                </Button>

                                            </Box>
                                        </Grid>
                                    </>
                                    :
                                    undefined
                                }


                                <EmailActivationModal
                                    open={openEmailActivationModal}
                                    triggerOpenModal={() => setOpenEmailActivationModal(true)}
                                    close={() => setOpenEmailActivationModal(false)}
                                    isMobile={isMobile}
                                    isTablet={isTablet}
                                    newData={newData}
                                    selectedImageNIK={selectedImageNIK}
                                    selectedImageKK={selectedImageKK}
                                    countdownTimer={countdownTimer}
                                    resetCountdown={() => setCountdownTimer(0)}
                                    errorMessage={errorMessage}
                                    emptyErrorMessage={() => setErrorMessage('')}
                                    cekUserProgress={cekUserProgress}
                                    monthAndYear={monthAndYear}
                                    asalKabupaten={asalKabupaten}
                                    statusActivationEmailTrue={() => setStatusActivationEmail(true)}
                                />
                                <UserRegisterProgressModal
                                    open={openUserRegisterProgressModal}
                                    close={() => setOpenUserRegisterProgressModal(false)}
                                    cekUserProgress={cekUserProgress}
                                    pageLoadingTrue={() => setIsPageLoading(true)}
                                    pageLoadingFalse={() => setIsPageLoading(false)}
                                    newData={newData}
                                    selectedImageKK={selectedImageKK}
                                    selectedImageNIK={selectedImageNIK}
                                    monthAndYear={monthAndYear}
                                    asalKabupaten={asalKabupaten}
                                    handleNavigasi={handleNavigasi}
                                    userProgress={userProgress}
                                />
                                {isPageLoading ?
                                    <LoadingData
                                        open={isPageLoading}
                                    // close={}
                                    />
                                    :
                                    undefined
                                }
                            </Grid >
                        </form>
                    </Box>
                </Grid >
                <ToastContainer style={{ width: '100%', maxWidth: isMobile ? '100%' : '350px' }} />
            </Grid >
            <Footer />
        </>
    );
};

export default RegistrasiTahap1;
